<template>
  <div
    :id="divId"
    :class="graphClass"
    :style="styles()"
  />
</template>

<script>
import uuidv4 from "uuid/v4";
import * as Highcharts from "highcharts/highcharts";
import * as HighchartsMore from "highcharts/highcharts-more";
import * as HighchartsPatternFill from "highcharts/modules/pattern-fill";
import * as HighchartsVariablePie from "highcharts/modules/variable-pie";
import * as HighchartsSunburst from "highcharts/modules/sunburst";
import * as HighchartsAnnotations from "highcharts/modules/annotations";
import * as HighchartsXrange from "highcharts/modules/xrange";
import * as HighchartsItemSeries from "highcharts/modules/item-series";

HighchartsMore(Highcharts);
HighchartsPatternFill(Highcharts);
HighchartsVariablePie(Highcharts);
HighchartsAnnotations(Highcharts);
HighchartsSunburst(Highcharts);
HighchartsXrange(Highcharts);
HighchartsItemSeries(Highcharts);
export default {
  name: "GraphHighcharts",
  props: {
    graphClass: {
      default: "graph",
      type: [String, Object]
    },
    data: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      divId: null,
      prevData: null
    };
  },
  watch: {
    data() {
      if (this.prevData !== JSON.stringify(this.data)) {
        this.prevData = JSON.stringify(this.data);
        this.$nextTick(() => Highcharts.chart(this.divId, this.data));
      }
    }
  },
  created: function() {
    if (this.id) {
      this.divId = this.id;
    } else {
      this.divId = "highChartId" + uuidv4();
    }
  },
  mounted: function() {
    this.prevData = JSON.stringify(this.data);
    Highcharts.chart(this.divId, this.data);
  },
  methods: {
    styles() {
      let styles = {};
      if (this.height) {
        styles.height = this.height + "%";
      }
      return styles;
    }
  }
};
</script>
